* {
  -webkit-touch-callout: none;
}

* {
  user-select: none;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

ion-navbar.toolbar.toolbar-ios.statusbar-padding,
ion-navbar.toolbar-ios ion-title.title-ios,
ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
ion-toolbar.toolbar-ios ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

@charset "utf-8";

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-display: fallback;
  src: local("Pretendard Bold"),
    url("../fonts/eot-subset/Pretendard-Bold.subset.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/woff2-subset/Pretendard-Bold.subset.woff2") format("woff2"),
    url("../fonts/woff-subset/Pretendard-Bold.subset.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: auto;
  src: local("Pretendard Medium"),
    url("../fonts/eot-subset/Pretendard-Medium.subset.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/woff2-subset/Pretendard-Medium.subset.woff2") format("woff2"),
    url("../fonts/woff-subset/Pretendard-Medium.subset.woff") format("woff");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: auto;
  src: local("Pretendard Regular"),
    url("../fonts/eot-subset/Pretendard-Regular.subset.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/woff2-subset/Pretendard-Regular.subset.woff2") format("woff2"),
    url("../fonts/woff-subset/Pretendard-Regular.subset.woff") format("woff");
}

.BackgroundScreen {
  overflow-y: auto;
  touch-action: none;
  user-select: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.MainScreen {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow-y: auto;
  touch-action: none;
  user-select: none;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #333;
  word-break: keep-all;
  font: 16px/1.5 Pretendard, "Malgun Gothic", "Dotum", "AppleGothic", sans-serif;
}

.TopArea {
  width: 100%;
  height: max-content;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.TopBottomLine {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #eeeeee;
}

.TopAreaStrong {
  font-size: 1.3em;
  color: #0d2a5e;
  font-weight: 500;
  display: block;
  margin-top: 24px;
  line-height: 1;
}

.MainLogoContainer {
  width: 100%;
}

.MainLogo {
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ContentArea {
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.InputArea {
  margin-bottom: 24px;
}

.InputDiv {
  width: 100%;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #c0c0c0;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.IdLabel {
  color: #222;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1;
  display: block;
  margin-bottom: 25px;
  text-align: left;
}

.InputLeftArea {
  width: 80%;
  margin: 0px;
  flex-direction: row;
  display: flex;
}

.IdIcon {
  width: 1.7rem;
  height: auto;
  margin: 0px;
  margin-bottom: 5px;
}

.Input {
  font-size: 1rem;
  padding-left: 10px;
  width: 100%;
  -moz-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.InputRight {
  top: 7px;
  right: 12px;
  margin-right: 10px;
}

.IdInputReset {
  background: url(../images/navienSmart/ico_ipt_reset.png) no-repeat 0 50%;
  width: 24px;
  height: 24px;
  transition: 0.1s;
}

.PwInputClose {
  background: url(../images/navienSmart/ico_pw_close.png) no-repeat 0 50%;
  width: 24px;
  height: 24px;
  transition: 0.1s;
}

.PwInputOpen {
  background: url(../images/navienSmart/ico_pw_open.png) no-repeat 0 50%;
  width: 24px;
  height: 24px;
  transition: 0.1s;
}

.LoginBtn {
  position: unset;
  width: 100%;
  height: 48px;
  margin-top: 30px;
  font-size: 1em;
  font-weight: normal;

  display: inline-block;
  background: #0d2a5e;
  border-radius: 4px;
  text-align: center;
  color: #fff;
}

.LoginBtn:disabled {
  background: #c0c0c0;
  color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.PopupScreen {
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  word-break: keep-all;
  font: 16px/1.5 Pretendard, "Malgun Gothic", "Dotum", "AppleGothic", sans-serif;
}

.Popup {
  display: inline-block;
  z-index: 999;
  background-color: white;
  width: 70vw;
  height: auto;
  border-radius: 4px;
  box-shadow: 3px 3px 6px #00000041;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.PopupTopArea {
  text-align: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #c0c0c0;
}

.PopupTopText {
  font-size: 1.6em;
  font-weight: bold;
  color: #0d2a5e;
  display: block;
  margin-top: 15px;
}

.PopupContentArea {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.PopupContentTitleText {
  font-size: 1em;
  color: black;
  margin-bottom: 10px;
}

.PopupContentSubText {
  font-size: 0.8em;
  color: #999;
}

.PopupConfirmBtnArea {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.PopupConfirmBtn {
  width: 45%;
  font-size: 1em;
  font-weight: bold;
  padding: 10px;
  background: #0d2a5e;
  border: 1px solid #0d2a5e;
  border-radius: 4px;
  color: #fff;
}

.container {
  font-family: arial;
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  outline: dashed 1px black;
}

p {
  text-align: left;
  margin: 20px 10px 20px 20px;
}